import { Box, Button, Hidden, Link, Typography, useMediaQuery, useTheme } from '@hermes/web-components'
import { animated, useInView } from '@react-spring/web'
import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import NextImage from 'next/image'
import { ArticleCardProps } from '../../types/article'
import { ShowcaseCardContainer, ShowcaseCardImageContainer } from './styles'

const ShowcaseCard = ({ title, description, previewImage, url }: ArticleCardProps) => {
  const router = useRouter()
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
      config: {
        duration: 400
      }
    }),
    {
      rootMargin: '-50px 0px'
    }
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [wightImg, heightImg] = useMemo(() => (isMobile ? ['786px', '270px'] : ['378px', '270px']), [isMobile])

  const onOpenArticle = async () => {
    await router.push(String(url), undefined, { shallow: true })
  }

  const AnimatedShowcaseCardContainer = animated(ShowcaseCardContainer)

  return (
    <AnimatedShowcaseCardContainer style={springs} ref={ref}>
      <ShowcaseCardImageContainer
        onClick={onOpenArticle}
        sx={{
          cursor: 'pointer'
        }}
        className="showcase-card-image-container"
      >
        <NextImage
          src={previewImage?.data?.attributes.url || '/img/default-cover.png'}
          height={heightImg}
          width={wightImg}
          objectFit="cover"
          alt={title}
        />
      </ShowcaseCardImageContainer>
      <Box className="showcase-card-content">
        <NextLink href={url || '/404'} passHref>
          <Typography
            variant="h3"
            component={Link}
            sx={{
              textDecoration: 'none',
              color: 'black',
              lineHeight: '32px'
            }}
          >
            {title}
          </Typography>
        </NextLink>
        <Typography
          onClick={onOpenArticle}
          sx={{
            cursor: 'pointer',
            marginTop: '35px',
            display: '-webkit-box',
            WebkitLineClamp: '7',
            wordBreak: 'break-word',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {description}
        </Typography>
      </Box>
      <Hidden mdUp implementation="css">
        <Button
          sx={{
            width: '100%',
            marginTop: '25px',
            padding: '17px 0'
          }}
          variant="outlined"
        >
          Details here
        </Button>
      </Hidden>
    </AnimatedShowcaseCardContainer>
  )
}

export default ShowcaseCard
